import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                         <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            "I was so excited to see this being brought to my neighborhood that I knew that would be the perfect place to host my son's birthday. And I was right.  Party planning was super easy. The owners were super communicative throughout the process and accommodated me by allowing me to bring the Decor early and they set everything up. All the guests really loved the facility enjoyed themselves. I wish I could give them 10 stars. It was the best party I've ever done and it's all because of them. Thank you!!!"
                            </p>
                            <p className="overview">Amii G.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "Visited yesterday to check them out for summer school. Awesome place! Lots of fun and perfect for our daughter. Excited for summer school!"
                            </p>
                            <p className="overview">Brian K.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "My little Eden loved playing here! It was clean, well staffed, friendly and safe. The owner, Kaela, was personable and made sure we had a great time while playing. We will definitely return!"
                            </p>
                            <p className="overview">
                            Kay M.</p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            "This is more than just a gym for our special little one, it's a Community. We are always treated with such a welcome embrace and sincerity towards our family. They have taken the time to get to know us personally and most importantly our little dude. Can't say enough about how much our kiddo loves to learn, play and challenge himself at every visit."
                            </p>
                            <p className="overview">
                            Colin H.</p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "I am a LMHC and highly recommended this place. Staff is super friendly and is in a great location. If you have littles this gym is amazing and a must! They do everything from play time to summer camps. The best part is they serve ALL children no matter what from neurotypical to rocking the spectrum!"
                            </p>
                            <p className="overview">Audra G.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "An amazing and safe place for children to play and grow! Such a fun experience for kids and parents. We enjoy their free play time and are excited to host our daughter’s birthday there this year!"
                            </p>
                            <p className="overview">
                            Lindsay K.
                            </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            "Awesome place for kids to enjoy!"
                            </p>
                            <p className="overview">
                            Donna D.
                             </p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
